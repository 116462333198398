// socketMiddleware.js
import { addPnlUpdates } from '../features/slices/alert/TransitionAlertsSlice';
import { tradePositions, setSplitoutData } from '../features/slices/trade_user/tradeUserSlice';
import { io } from 'socket.io-client';
let user_socket = null;

const socketMiddleware = (storeAPI) => {
  return (next) => (action) => {
    const result = next(action);
    const currentState = storeAPI.getState();
    
    if (currentState.tradeUsers.currentUser && currentState.user.loggedIn){
        if (user_socket) return;
        user_socket = io(process.env.REACT_APP_BASE_URL_SOCKET, { transports: ['websocket'], path:"/user-socket", withCredentials: true, autoConnect:false });
        user_socket.connect();
        storeAPI.dispatch(tradePositions());
        user_socket.on('alert', (data) => {
            const status = data?.status
            if(status === "REJECTED" || status === "CANCELLED" || status === "COMPLETE"){
                if (status !== "REJECTED"){
                    storeAPI.dispatch(tradePositions());
                }
                storeAPI.dispatch(addPnlUpdates({message: data?.message ?? "", type: status === "COMPLETE" ? "success" : "error"}));
            }
        });
        user_socket.on('splitout_d', (data) => {
            storeAPI.dispatch(setSplitoutData(data));
        });
        user_socket.on('connect', (data) => {
            user_socket.emit('g_data', 'sp_dta');
        });
    }else if(user_socket?.connected){
        user_socket.disconnect();
        user_socket = null;
    }
    return result;
  };
};

export default socketMiddleware;
