import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { updateChain, updateChart } from "../../features/slices/table/tablePrefrenceSlice";
import { useSelector, useDispatch } from 'react-redux';
import OptionChain from '../Chain/OptionChain';
import Chart from "../Charts/Chart";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'black',
  height: 'auto',
  outline:'none',
  // boxShadow: 24,
  p: '0.2rem', 
};

const DataModal = () => {
  
  const dispatch = useDispatch();
  const chainInstrument = useSelector((state) => state.tablePrefrence.chain.instrument);
  const chainExpiry = useSelector((state) => state.tablePrefrence.chain.expiry);
  const chartInstrument = useSelector((state) => state.tablePrefrence.chart.instrument);
  const chartExpiry = useSelector((state) => state.tablePrefrence.chart.expiry);
  const chartChange = useSelector((state) => state.tablePrefrence.chart.change);
  const chartPrice = useSelector((state) => state.tablePrefrence.chart.price);
  const chartIv = useSelector((state) => state.tablePrefrence.chart.iv);
  const chartType = useSelector((state) => state.tablePrefrence.chart.type);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  React.useEffect(()=>{
    if (chainInstrument || chartInstrument){
      setOpen(true)
    }else{
      setOpen(false)
    }
  }, [chainInstrument, chartInstrument])

  React.useEffect(()=>{
    if (!open){
      if(chainInstrument){
        dispatch(updateChain({
          "instrument": "",
          "expiry": ""
        }))
      }else if (chartInstrument){
        dispatch(updateChart({
          "instrument": "",
          "expiry": ""
        }))
      }
    }
  // eslint-disable-next-line
  }, [open, dispatch])

  return (
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="Chain"
        aria-describedby="Option Chain">
        <Box sx={style}>
         {
          chainInstrument && chainExpiry ?
            <OptionChain chainInstrument={chainInstrument} chainExp={chainExpiry} />
          :chartInstrument ?
            <Chart instrument={chartInstrument} iv={chartIv} price={chartPrice} expiry={chartExpiry} change={chartChange ?? null} type={chartType} />
          :null
         }
        </Box>
      </Modal>
  );
}

export default DataModal;
