import { createSlice } from '@reduxjs/toolkit';

const tablePrefrenceSlice = createSlice({
  name: 'tablePrefrence',
  initialState: {
    chain: {
      instrument: "",
      expiry: "",
    },
    chart: {
      instrument: "",
      expiry: "",
      change: "",
      type: "F",
      price: 0,
      iv: 0,
    },
  },
  reducers: {
    updateChain: (state, action) => {
      state.chain.instrument = action.payload.instrument
      state.chain.expiry = action.payload.expiry
    },
    updateChart: (state, action) => {
      state.chart.instrument = action.payload.instrument
      state.chart.type = action.payload.type
      state.chart.expiry = action.payload.expiry
      state.chart.change = action.payload.change
      state.chart.iv = action.payload.iv
      state.chart.price = action.payload.price
    },
  },
});

export const { updateChart, updateChain } = tablePrefrenceSlice.actions;
export default tablePrefrenceSlice.reducer;
