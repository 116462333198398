import React, { createContext } from 'react';
import { io } from 'socket.io-client';
export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  // eslint-disable-next-line
  const [socket, setSocket] = React.useState(io(process.env.REACT_APP_BASE_URL_SOCKET+ 'wait-list', { transports: ['websocket'], path:"/socket", withCredentials: true, autoConnect:false }));

  const disconnectSocket = () =>{
    // console.log("Disconnecting...");
    socket.disconnect();
  }
  const connectSocket = () =>{
    // console.log("Connecting...");
    socket.connect();
  }

  React.useEffect(()=>{
    if (socket){
      socket.on('connect_error', (error) => {
        console.log('connect_error');
      });
  
      socket.on('reconnect', (attemptNumber) => {
        console.log(`Successfully reconnected on attempt #${attemptNumber}`);
      });
  
      socket.on('reconnect_error', (error) => {
        console.error('Reconnection error:', error);
      });
  
      socket.on('reconnect_failed', () => {
        console.error('Reconnection failed');
      });
    }

    return () => {
      if (socket) {
        socket.off('connect_error');
        socket.off('reconnect');
        socket.off('reconnect_error');
        socket.off('reconnect_failed');
        socket.off('disconnect');
      }
    };
  },[socket])

  return (
    <SocketContext.Provider value={{socket, disconnectSocket, connectSocket}}>
      {children}
    </SocketContext.Provider>
  );
};