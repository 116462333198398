import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {tradeUser} from '../../../utils/axiosInstance';
import { createAlert } from "../alert/TransitionAlertsSlice";
import { setLoader } from "../loader/loaderSlice";

const initialState = {
  currentUser: "",
  users: [],
  marktPositions: {},
  balances: {},
  splitout_data: {},
  whatIf: {},
  possibleLoss: {}
};

export const tradeUsers = createAsyncThunk('trade-user/get-all', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.get('user/get-all');
  dispatch(setLoader(false))
  return response.data;
});
export const tradePositions = createAsyncThunk('trade-user/positions', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.get('user/positions');
  dispatch(setLoader(false))
  return response.data;
});
export const placeOrderMarket = createAsyncThunk('trade-user/placeOrder', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.post('orders/execute', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});
export const startSplit = createAsyncThunk('trade-user/startSplit', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.post('orders/split-start', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});
export const splitModify = createAsyncThunk('trade-user/splitModify', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.post('orders/split-modify', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});
export const stopSplit = createAsyncThunk('trade-user/stopSplit', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.post('orders/split-stop', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});
export const getWhatIf = createAsyncThunk('info/whatIf', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.post('info/bwhatif', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});
export const getPossibleLoss = createAsyncThunk('info/possibleLoss', async (req, {dispatch}) => {
  dispatch(setLoader(true))
  const response = await tradeUser.post('info/getPossibleLoss', req);
  dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  dispatch(setLoader(false))
  return response.data;
});

const tradeUserSlice  = createSlice({
  name: 'trade-user',
  initialState,
  reducers: {
    setCurrentUser: (state, payload) => {
      localStorage.setItem('trade-user', payload.payload);
      state.currentUser = payload.payload;
    },
    clearWhatIf: (state) => {
      state.whatIf = {};
    },
    clearPossibleLoss: (state) => {
      state.possibleLoss = {};
    },
    setSplitoutData: (state, payload) => {
      state.splitout_data = payload.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(tradeUsers.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
          state.users = action.payload.list;
          if (!state.currentUser){
            state.currentUser = localStorage.getItem('trade-user') ?? state.users[0]
          }
        }else{
          state.users = [];
          state.currentUser = "";
        }
      })
      .addCase(tradeUsers.rejected, (state) => {
        state.users = []; 
        state.currentUser = "";
      })
      .addCase(tradePositions.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
          state.marktPositions = action.payload.data;
          if (state.marktPositions?.balances){
            state.balances = state.marktPositions.balances 
          }
        }else{
          state.marktPositions = {};
          state.balances = {}
        }
      })
      .addCase(tradePositions.rejected, (state) => {
        state.marktPositions = {};
      })
      .addCase(getWhatIf.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
          state.whatIf = action.payload.data;
          if (state.whatIf?.negRange && Array.isArray(state.whatIf?.negRange) && state.whatIf.negRange.length && state.whatIf?.posRange &&  Array.isArray(state.whatIf?.posRange) && state.whatIf.posRange.length){
            state.whatIf.avg = (parseFloat(state.whatIf?.negRange[0]) + parseFloat(state.whatIf?.posRange[0]))/2
          }
        }
      })
      .addCase(getWhatIf.rejected, (state) => {
        state.whatIf = {};
      })
      .addCase(getPossibleLoss.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
          state.possibleLoss = action.payload.data;
          if (state.possibleLoss?.negRange && Array.isArray(state.possibleLoss?.negRange) && state.possibleLoss.negRange.length && state.possibleLoss?.posRange &&  Array.isArray(state.possibleLoss?.posRange) && state.possibleLoss.posRange.length){
            state.possibleLoss.avg = ((Math.abs(parseFloat(state.possibleLoss?.negRange[0])) + parseFloat(state.possibleLoss?.posRange[0]))/2).toFixed(2);
          }
        }
      })
      .addCase(getPossibleLoss.rejected, (state) => {
        state.possibleLoss = {};
      })
  },
});

export const {setCurrentUser, setSplitoutData, clearWhatIf, clearPossibleLoss} = tradeUserSlice.actions 

export default tradeUserSlice.reducer;
