import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createAlert } from "../alert/TransitionAlertsSlice";
import { setLoader } from "../loader/loaderSlice";

const initialState = {
  connectionStatus: '',
  updateInterval: 0.5
};

export const connectToSocket = createAsyncThunk('connectToSocket', async function (e, {dispatch}) {
  // const response = await socketClient.connect();
  const response = null;
  // socketClient.on("alert", (event) => {
  //     if(event.message && event.type){
  //         const message = event.message
  //         const type = event.type
  //         dispatch(createAlert({type, message}));
  //     }
  // })
  return response
});

export const disconnectFromSocket = createAsyncThunk('disconnectFromSocket', async function () {
  // if(socketClient.socket){
  //   socketClient.off("alert")
  // }
  // return await socketClient.disconnect();
});


export const emitDataSocket = createAsyncThunk('emitFromSocket', async function ({req, socket}, {dispatch}) {
  dispatch(setLoader(true))
  const event = req["event"]
  delete req.event
  const response = await socket.emit(event, req);
  dispatch(setLoader(false))
  if(response.data.message)
    dispatch(createAlert({message: response.data.message, type: response.data?.success ? 'success' : 'error', timeout: 2000}))
  return null
});

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setUpdateInterval: (state, action) => {
      state.updateInterval = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(connectToSocket.pending, (state) => {
      state.connectionStatus = 'connecting';
    })
    .addCase(connectToSocket.fulfilled, (state) => {
      state.connectionStatus = 'connected';
    })
    .addCase(connectToSocket.rejected, (state) => {
      state.connectionStatus = 'connection failed';
    })
    .addCase(disconnectFromSocket.pending, (state) => {
      state.connectionStatus = 'disconnecting';
    })
    .addCase(disconnectFromSocket.fulfilled, (state) => {
      state.connectionStatus = 'disconnected';
    })
    .addCase(disconnectFromSocket.rejected, (state) => {
      state.connectionStatus = 'disconnection failed';
    });
  },
});

export const { updateChainData, setUpdateInterval } = socketSlice.actions;
export default socketSlice.reducer;