// axiosInstance.js

import axios from 'axios';

export const apiUser = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_USER, // Your base URL
  withCredentials: true
});

export const tradeUser = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_TRADE_USER, // Your base URL
  withCredentials: true
});


// Add a response interceptor
// apiUser.interceptors.response.use(
//   (response) => {
//     if (response.status === 401){
//       window.location.reload()
//     }
//     // You can modify the response data here
//     // For example, log the response, parse data, etc.
//     return response;
//   },
//   (error) => {
//     // Handle response errors
//     return Promise.reject(error);
//   }
// );

export const socketAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_SOCKET_API, // Your base URL
  withCredentials: true
});

export const kiteAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_KITE, // Your base URL
  withCredentials: true
});

export const histAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_HIST, // Your base URL
  withCredentials: true
});

// export default instance;
