// CustomSnackbarProvider.js
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { SuccessSnackbar, ErrorSnackbar, WarningSnackbar, InfoSnackbar } from './CustomSnackbarComponents';

const CustomSnackbarProvider = ({ children }) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    preventDuplicate={true}
    Components={{
      success: SuccessSnackbar,
      error: ErrorSnackbar,
      warning: WarningSnackbar,
      info: InfoSnackbar,
    }}
  >
    {children}
  </SnackbarProvider>
);

export default CustomSnackbarProvider;
