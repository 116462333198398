import React from 'react';
import { Box, Typography } from '@mui/material';
import DataGrid from 'react-data-grid';
import { sortRows } from "./tableFunctions";
import { kiteAPI } from "../../utils/axiosInstance";
import moment from 'moment';

const columns = {
    "corp_action": [
        {
            key: "portfolio",
            resizable: true,
            sortable: true,
            minWidth: 150,
            type: "string",
            name: "Portfolio",
        },
        {
            key: "eventDate",
            resizable: true,
            sortable: true,
            minWidth: 110,
            sortComparator: (a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')),
            type: "date",
            name: "Date",
        },
        {
            key: "eventDay",
            resizable: true,
            sortable: true,
            minWidth: 90,
            type: "day",
            sortComparator: (a, b) => {
                const dayOfWeekA = moment(a, 'dddd').isoWeekday(); // Convert 'Thursday' to day of the week (1 = Monday, 7 = Sunday)
                const dayOfWeekB = moment(b, 'dddd').isoWeekday();
                return dayOfWeekA - dayOfWeekB; // Compare the weekday numbers
            },
            name: "Day",
        },
        {
            key: "description",
            resizable: true,
            sortable: true,
            width: '35vw',
            type: "string",
            renderCell: (c)=> (<Typography title={c.row.description ?? ''} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>{c.row.description ?? ''} </Typography>),
            name: "Event",
    
        },
        {
            key: "year",
            resizable: true,
            sortable: true,
            minWidth: 75,
            type: "number",
            renderCell: (c)=> (<span>{`${c.row.year}${c.row.quarter ? `-Q${c.row.quarter}` : ""}`} </span>),
            name: "Yr-Qtr",
            sortComparator: (a, b) => {
                const momentA = moment(a, 'YYYY-[Q]Q'); // Parse '2024-Q2'
                const momentB = moment(b, 'YYYY-[Q]Q');
                
                if (momentA.year() !== momentB.year()) {
                  return momentA.year() - momentB.year(); // First compare the years
                }
                return momentA.quarter() - momentB.quarter(); // If the years are the same, compare the quarters
            }
        },
    ],
    "comp_ev":[
        {
            key: "portfolio",
            resizable: true,
            sortable: true,
            minWidth: 150,
            type: "string",
            name: "Portfolio",
        },
        {
            key: "eventDate",
            resizable: true,
            sortable: true,
            minWidth: 110,
            type: "date",
            sortComparator: (a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')),
            name: "Date",
        },
        {
            key: "eventDay",
            resizable: true,
            sortable: true,
            minWidth: 90,
            type: "day",
            sortComparator: (a, b) => {
                const dayOfWeekA = moment(a, 'dddd').isoWeekday(); // Convert 'Thursday' to day of the week (1 = Monday, 7 = Sunday)
                const dayOfWeekB = moment(b, 'dddd').isoWeekday();
                return dayOfWeekA - dayOfWeekB; // Compare the weekday numbers
            },
            name: "Day",
        },
        {
            key: "name",
            resizable: true,
            sortable: true,
            minWidth: 150,
            type: "string",
            name: "Name",
        },
        {
            key: "description",
            resizable: true,
            sortable: true,
            width: '40vw',
            type: "string",
            renderCell: (c)=> (<Typography title={c.row.description ?? ''} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>{c.row.description ?? ''} </Typography>),
            name: "Event",
    
        },
        {
            key: "year",
            resizable: true,
            sortable: true,
            minWidth: 75,
            type: "number",
            renderCell: (c)=> (<span>{`${c.row.year}${c.row.quarter ? `-Q${c.row.quarter}` : ""}`} </span>),
            name: "Yr-Qtr",
            sortComparator: (a, b) => {
                const momentA = moment(a, 'YYYY-[Q]Q'); // Parse '2024-Q2'
                const momentB = moment(b, 'YYYY-[Q]Q');
                
                if (momentA.year() !== momentB.year()) {
                  return momentA.year() - momentB.year(); // First compare the years
                }
                return momentA.quarter() - momentB.quarter(); // If the years are the same, compare the quarters
            }
        },
    ]
}

export default function EventsTable({eventType}) {
    const [rows, setRows] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [sortColumn, setSortColumn] = React.useState([]);

    const handleSort = (s) => {
        
        setSortColumn(s.map((s1)=>{
            const ind = columns[eventType].findIndex((i)=> s1.columnKey === i.key)
            if (ind !== -1){
                return(columns[eventType][ind].sortComparator ?{...s1, sortComparator: columns[eventType][ind].sortComparator} : s1)
            }else{
                return(s1);
            }
        }));
    };

    const getData = React.useCallback(async () => {
        const response = await kiteAPI.get(`events/get/${eventType}?fromDate=${fromDate}&toDate=${toDate}`);
        if (response.status === 200 && response.data.data){
            setList(response.data.data)
        }else{
            setList([])
        }
        setLoading(false)
    }, [fromDate, toDate, eventType])

    React.useEffect(()=>{
        setLoading(true)
        getData()
    }, [getData])

    React.useEffect(()=>{
        setRows(sortColumn.length ? sortRows(list, sortColumn) : list);
    },[sortColumn, list]);

  return (
    <Box sx={{width: '95%', margin: '0px auto'}}>
        {
            loading ? <Box sx={{textAlign: 'center', fontSize: '2rem'}}>Loading...</Box> : 
            rows.length ? 
                <DataGrid
                    style={{fontSize: '1.1rem', fontWeight: 400, height: '90vh', maxHeight:'calc(100vh - 100px) !important', textAlign: 'center'}} 
                    rowHeight={30}
                    headerRowHeight={35}
                    sortColumns={sortColumn} onSortColumnsChange={handleSort}
                    // rowClass={(p => p.ban ? `textclr-red` : `textclr-green`)}
                    columns={columns[eventType]}
                    rows={rows}
                />
            : <Box sx={{textAlign: 'center', fontSize: '2rem'}}>No F&O Stocks...</Box>
        }
    </Box>
  )
}