// eslint-disable-next-line
module.exports = global = {
    events: {
        type: {
            label: "Event Type",
            list:{
                corporateActions: "Corporate Actions" ,
                eventCalendar: "Event Calendar",
                economicsCalendar: "Economics Calendar"
            }
        },
        corporateActions: {
            label: "Corporate Actions",
            list:{
            }
        },
        eventCalendar: {
            label: "Event Calendar",
            list:{
            }
        },
        economicsCalendar: {
            label: "Economics Calendar",
            list:{
            }
        },
    }
};