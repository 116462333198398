// CustomSnackbarComponents.js
import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import pop from '../../assets/pop.mp3';

export const playNotificationSound = () =>  {
  const audio = new Audio(pop); // Use the correct path to your file
  audio.play().catch(error => console.error("Error playing sound:", error));
}

const createSnackbarComponent = (bgcolor) =>
  React.forwardRef(({ id, message }, ref) => {
    const { closeSnackbar } = useSnackbar();
    return (
      <Box
        ref={ref}
        display="flex"
        alignItems="center"
        bgcolor={bgcolor}
        p={.5}
        borderRadius={1}
      >
        <Typography variant="body2" sx={{ ml: 1, flex: 1, width: '9rem', fontSize: '0.8rem'}}>
          {message}
        </Typography>
        <IconButton onClick={() => closeSnackbar(id)} size="small" color="inherit">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  });

export const SuccessSnackbar = createSnackbarComponent('success.dark');
export const ErrorSnackbar = createSnackbarComponent('error.dark');
export const WarningSnackbar = createSnackbarComponent('warning.dark');
export const InfoSnackbar = createSnackbarComponent('info.dark');
