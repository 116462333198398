import React from 'react'
import { Box, Typography, Popover } from '@mui/material';
import { clearPossibleLoss, getPossibleLoss } from "../../features/slices/trade_user/tradeUserSlice";
import { useDispatch, useSelector } from 'react-redux';

function PossibleLoss({possibleLossParams, setPossibleLoss}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const possibleLoss = useSelector((state) => state.tradeUsers.possibleLoss);

    const getPossibleLossData = React.useCallback((portfolio, expiry) => {
        dispatch(getPossibleLoss({portfolio, expiry}));
    }, [dispatch]);
    
    React.useEffect(()=>{
        if (possibleLossParams?.request?.portfolio && possibleLossParams?.position){
            getPossibleLossData(possibleLossParams.request.portfolio, possibleLossParams.request.expiry);
            setAnchorEl(possibleLossParams.position);
        }
    }, [possibleLossParams, getPossibleLossData])

    return (
        <Popover 
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
            }}
            onClose={()=>{setAnchorEl(null); setPossibleLoss({}); dispatch(clearPossibleLoss())}}
        >
            <Box sx={{background: 'black',  padding:'1rem'}}>
            {
                possibleLoss?.negRange ?
                <>
                <Typography sx={{margin: 0, color: '#f72b2b'}}>{possibleLoss?.negRange?.toString()}</Typography>
                <Typography sx={{margin: 0, color: '#22c55e'}}>{possibleLoss?.posRange?.toString()}</Typography>
                <Typography sx={{margin: 0, color: '#c0fb13'}}>{possibleLoss?.avg}</Typography>
                </>
                : <Typography sx={{margin: 0, color: '#fff'}}>Loading...</Typography>
            }
            </Box>
        </Popover>
    )
}

export default PossibleLoss