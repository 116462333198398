import * as React from 'react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import moment from 'moment';

Chart.register(annotationPlugin);

const chartConfig = {
  price: {
    label: "Price",
    yAxis: "yPrice",
    position: 'left',
  },
  iv: {
    label: "IV",
    yAxis: "yIv",
    position: 'right',
    color: '#fff'
  },
  skew: {
    label: "Skew",
    yAxis: "yIv",
    position: 'right',
    color: '#fff'
  },
  oi: {
    label: "Fut_OI",
    yAxis: "yIv",
    position: 'right',
    color: '#fff'
  },
  oi_rto: {
    label: "PCR OI",
    yAxis: "yIv",
    position: 'right',
    color: '#fff'
  },
  vol_rto: {
    label: "PCR Vol",
    yAxis: "yIv",
    position: 'right',
    color: '#fff'
  }
}

const LineChart = ({ data, change, fields=[]}) => {
    const canvasRef = React.useRef(null);
    const chartRef = React.useRef(null);
    const dragging = React.useRef({start: null, end: null});
    const label = React.useRef({});
    
    React.useEffect(() => {
      const ctx = canvasRef.current.getContext('2d');
      // Destroy the existing chart if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      
      if(data.length){
        chartRef.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: data.map((d) => d[0]),
            datasets: fields.map((d, i)=> ({
              label: chartConfig[d].label,
              data: data.map(d => (d[i+1] ?? 0)),
              pointRadius: 1,
              yAxisID: chartConfig[d].yAxis,
              pointHoverRadius: 1.1,
              tension: 0.1,
              backgroundColor: (chartConfig[d].color ?? (change > 0 ? '#22c55e' : '#f72b2b')),
              segment: {
                borderColor: ctx => {
                  const { start, end } = dragging.start ? dragging : { start: 0, end: 0 };
                  const index = ctx.p0DataIndex;
                  const [minIndex, maxIndex] = start < end ? [start, end] : [end, start];
                  if (index >= minIndex && index < maxIndex) {
                    return 'rgb(54, 162, 235)';
                  }
                  return (chartConfig[d].color ?? (change > 0 ? '#22c55e' : '#f72b2b'));
                },
              }
            }))
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: true,
                position: 'nearest',
                intersect: false,
                axis: 'x',
                bodyFont: {
                    size: 20
                },
                titleFont: {
                    size: 20
                },
                callbacks: {
                  title: (th) => {
                    if (dragging?.start != null) {
                      let start = "";
                      let end = "";
                      
                      if(label.diff < 0){
                        end = label.Start.format(Math.abs(label.diff) <= 360 ? 'hh:mm A' : 'DD MMM hh:mm')
                        start = label.End.format(Math.abs(label.diff) <= 360 ? 'DD MMM hh:mm A' : 'DD MMM hh:mm')
                      }else{
                        start = label.Start.format(Math.abs(label.diff) <= 360 ? 'DD MMM hh:mm A' : 'DD MMM hh:mm')
                        end = label.End.format(Math.abs(label.diff) <= 360 ? 'hh:mm A' : 'DD MMM hh:mm')
                      }
                      return `${start} - ${end}`;
                    }

                    const date = moment(chartRef.current.data.labels[th[0].dataIndex])
                    return date.format('ddd, DD MMM hh:mm A');
                  },
                  label: (l) => {
                    if (dragging.start != null){
                      const start = l.dataset.data[dragging.start];
                      const end = l.raw;
                      if(label.diff < 0){
                        const chg = (((start - end) / end) * 100).toFixed(2);
                        label.change = {...(label.change) ?? {}, [l.datasetIndex]: chg};
                        return `(${end} - ${start}) ${chg}%`;
                      }else{
                        const chg = (((end - start) / start) * 100).toFixed(2);
                        label.change = {...(label.change) ?? {}, [l.datasetIndex]: chg};
                        return `(${start} - ${end}) ${chg}%`;
                      }
                      
                    }
                  },
                  labelTextColor : (l) => {
                    return (label?.change ? (label.change[l.datasetIndex] >= 0 ? '#22c55e' : '#f72b2b' ): "")
                  }
                }
              },
            },
            scales: {
              x: {
                type: 'timeseries',
                offset: true,
                ticks: {
                  major: {
                    enabled: true,
                  },
                  color: 'white',
                  maxRotation: 0,
                  autoSkip: true,
                  autoSkipPadding: 75,
                },
              },
              ...fields.reduce((a,c,i) => ({...a, ...{[chartConfig[c].yAxis]: {
                position: chartConfig[c].position,
                type: 'linear',
                offset: true,
                min: Math.min(...data.map(d => (d[i+1] ?? 0))),  // Setting min based on IV data
                max: Math.max(...data.map(d => (d[i+1] ?? 0))),
                title: {
                  display: false
                },
                ticks: {
                  color: 'white'
                }
              }}}), {} )
            },
            animation: {
              duration: 2,
              easing: 'easeOutQuad'
            },
            events: ['mousemove', 'mousedown', 'mouseup', 'touchstart', 'touchmove'],
            onHover: function (e) {
              const chart = this;
              const { native } = e;
    
              if (native) {
                const points = chart.getElementsAtEventForMode(native, 'nearest', { intersect: false, axis: 'x' }, true);
    
                if (points.length) {
                  if (native.type === 'mousedown' || native.type === 'touchstart') {
                      dragging.start = dragging.end = points[0].index;
                      label.Start = moment(chart.data.labels[points[0].index]);
                      label.End = moment(chart.data.labels[points[0].index]);
                      chart.update();
                  }
                  else if ((native.type === 'mousemove' || native.type === 'touchmove') && dragging.start) {
                      dragging.end = points[0].index;
                      label.End = moment(chart.data.labels[points[0].index]);
                      label.diff = label.End.diff(label.Start, 'm') ?? 0
                      chart.update();
                  }
                }
                if (native.type === 'mouseup') {
                  dragging.start = dragging.end = null;
                  label.Start = label.End = label.diff = label.change = null
                  chart.update();
                }
              }
            }
          }
        });
      }
  
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [data, fields, change]); // This ensures the chart updates when `data` changes
  
    return (
      <canvas ref={canvasRef} />
    );
};

export default LineChart;