import React from 'react'
import './TradeUserPosition.css';
import { Box, Button, TextField, IconButton, Typography, Popover, Checkbox } from '@mui/material';
import { TreeDataGrid } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {SocketContext}  from '../../utils/SocketContext';
import { updateChain } from "../../features/slices/table/tablePrefrenceSlice";
import { placeOrderMarket, splitModify, stopSplit, startSplit, getWhatIf, clearWhatIf } from "../../features/slices/trade_user/tradeUserSlice";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import moment from 'moment-timezone';
import { sortRows, sum, rowGrouper } from "./tableFunctions";

const def_split ={user: "", portfolio: "", exposure: 5, agres: 0.05, type: "", delta_order: true}

const rowClass = (n) => {
  if (n && n.tPNL)
    return (n.tPNL >= 0 ? "textclr-green" : "textclr-red")
  return (n >= 0 ? "textclr-green" : "textclr-red")
}

const groupsOpt = [["user","groupKey"], ["groupKey","strike"], []]
const placeOdrMarket = (qty, selectedRows, setSelectedRows, dispatch, orderAgress) => {
  const body = {
    "instruments": Array.from(selectedRows), 
    "qty": qty, 
    "agress": parseFloat(orderAgress)
  }
  dispatch(placeOrderMarket(body))
  setSelectedRows(new Set())
}

const handleSort = (sortColumns, setSortColumn) => {
  setSortColumn([...sortColumns]);
};

const trade_portfolio_index = (localStorage.getItem('trade_portfolio_index')) ? Number(localStorage.getItem('trade_portfolio_index')) : 1;

const TradeUserPosition = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { socket } = React.useContext(SocketContext);
  const [groupIndexOpt, setGroupIndexOpt] = React.useState(trade_portfolio_index);
  const [group, setGroup] = React.useState(groupsOpt[groupIndexOpt]);
  const balances = useSelector((state) => state.tradeUsers.balances);
  const whatIf = useSelector((state) => state.tradeUsers.whatIf);
  const splitout_data = useSelector((state) => state.tradeUsers.splitout_data);
  const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set());
  const [selectedRows, setSelectedRows] = React.useState(new Set());
  const [sData, setSData] = React.useState({});
  const [hovType, setHovType] = React.useState("");
  const [rData, setRData] = React.useState([]);
  const [postions, setPostions] = React.useState([]);
  const [insExpData, setInsExpData] = React.useState({});
  const [totalSumPNLDict, setTotalSumPNLDict] = React.useState({});
  const [sortColumn, setSortColumn] = React.useState([]);
  const updateInterval = useSelector((state) => state.socket.updateInterval);
  const tradeMarktPositions = useSelector((state) => state.tradeUsers.marktPositions);
  const [orderLot, setOrderLot] = React.useState(1);
  const [orderAgress, setOrderAgress] = React.useState(0.05);
  const [anchorBS, setAnchorBS] = React.useState(null);
  const [split, setSplit] = React.useState(def_split);

  const getWhatIfData = React.useCallback((portfolio, expiry, user, position) => {
    dispatch(getWhatIf({user, portfolio, expiry }));
    setHovType('whatIf');
    setAnchorEl(position);
  }, [dispatch]);

  const show_chain = React.useCallback((instrument, expiry) => {
    dispatch(updateChain({
      "instrument": instrument,
      "expiry": expiry
    }))
  }, [dispatch])

  const handleSplit = React.useCallback(async (type=null, portfolio=null, user=null) => {
    const Body = {
      portfolio: portfolio ?? split.portfolio,
      user: user ?? split.user,
      agress: split.agres,
      exposure: split.exposure,
      delta_order: split.delta_order
    };
    switch(type ?? split.type) {
      case "stop":
        dispatch(stopSplit(Body));
        break;
      case "start":
        dispatch(startSplit(Body));
        break;
      case "modify":
        dispatch(splitModify(Body));
        break;
      default:
        break
    }
    setAnchorBS(null); 
    setSplit(def_split);

  }, [dispatch, split, setSplit])

  React.useEffect(()=>{
    if (tradeMarktPositions?.instruments){
      setPostions(tradeMarktPositions.instruments)
    }
  }, [tradeMarktPositions]);

  const GroupRowCell = React.useCallback(({ id, row }) => {
    if(insExpData?.[row.groupKey]?.[id])
      return (<span className={rowClass(sum(row.childRows, "tPNL"))}>{ insExpData[row.groupKey][id] ?? 0 }</span>)
    return null
  } , [insExpData])

  const GroupRow = React.useCallback((row) => {
    if (row.column.key === "user" && group[0] === "user")
      return <div className={rowClass(sum(row.childRows, "tPNL"))} style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: '0.9rem'}}>{row.groupKey}{row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
    else if(row.column.key === "groupKey"){
      const r = row.childRows[0]
      return <div onDoubleClick={()=>{show_chain(r.insName, r.expStr)}} className={rowClass(sum(row.childRows, "tPNL"))} style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: '0.9rem'}}>{row.groupKey}{row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
    }
    else if(row.column.key === "strike"){
      if (balances?.[row.groupKey]){
        return <span>Bal:<span style={{color: balances[row.groupKey].balance > 0 ? "#22c55e" : "#f72b2b", }}>{balances[row.groupKey].balance}</span></span>
      }
      else if(insExpData[row.row.groupKey]){
        if (splitout_data?.[row.row.parentId]?.[row.row.groupKey]){
          return(
            <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <IconButton aria-label="edit" color="warning" onClick={(e)=> { setAnchorBS(e.currentTarget); setSplit({...split, user: row.row.parentId, portfolio: row.row.groupKey, type: "modify", exposure: splitout_data[row.row.parentId][row.row.groupKey].exposure, agres: splitout_data[row.row.parentId][row.row.groupKey].agress, delta_order: splitout_data[row.row.parentId][row.row.groupKey].delta_order }) }}>
                <ModeOutlinedIcon />
              </IconButton>
              <IconButton aria-label="edit" color="secondary" onClick={()=> {handleSplit("stop", row.row.groupKey, row.row.parentId);}}>
                <StopOutlinedIcon />
              </IconButton>
            </Box>
          );
        }else{
          return(
          <IconButton aria-label="edit" color="primary" onClick={(e)=> { setAnchorBS(e.currentTarget); setSplit({...split, user: row.row.parentId, portfolio: row.row.groupKey, type: "start"}) }}>
            <PlayArrowOutlinedIcon />
          </IconButton>
          );

        }
      }
      else{
        // const r = row.childRows[0]
        return <div className={rowClass(sum(row.childRows, "tPNL"))} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{row.groupKey} {row.isExpanded ? <KeyboardArrowDownIcon color={'primary'} /> : <KeyboardArrowRightIcon color={'primary'} /> }</div>
      }
    }
    return null
  } , [insExpData, balances, show_chain, group, splitout_data, handleSplit, split])

  const GroupSelect = React.useCallback(() => {
    const groupChange = () => {
      const nexIndex = (groupsOpt.length -1) === groupIndexOpt ? 0 : groupIndexOpt + 1
      setGroup(groupsOpt[nexIndex]);
      setGroupIndexOpt(nexIndex);
      localStorage.setItem('trade_portfolio_index', nexIndex)
    };

    return(<Button onClick={groupChange} sx={{position: 'absolute', zIndex: 1, left: 0, height: '2.3rem', marginLeft: 1}} size='small'><CompareArrowsIcon/></Button>)
  } , [setGroup, groupIndexOpt, setGroupIndexOpt])

  const onCellClick = React.useCallback((r, check=true) => {
    if (check && r.column.key === "select-row"){
      return;  // Do nothing when clicking on select-row column
    }
    const id = r.row.id
    const tmp = selectedRows;
    if (tmp.has(id)) {
      tmp.delete(id)
      setSelectedRows(new Set(tmp))
    }else{
      tmp.add(id)
      setSelectedRows(new Set(tmp))
    }
  }, [selectedRows]);
  
  const m_columns =  React.useMemo( () => {
    return [
      {
        key: "select-row",
        name: "",
        width: 45,
        minWidth: 45,
        maxWidth: 45,
        frozen: true,
        renderCell: (r) => <Checkbox sx={{p:0}} checked={selectedRows.has(r.row.id)} onClick={()=>onCellClick(r, false)}/>,
        renderGroupCell: (r) => {
          const colIds = r.childRows.map(row => row.id);
          const check = (selectedRows.intersection(new Set(colIds)));
          const indeterminate = check.size && check.size < colIds.length;
          const checked = check.size && check.size === colIds.length;
          return <Checkbox sx={{p:0}} indeterminate={Boolean(indeterminate)} checked={Boolean(checked)} onClick={() => indeterminate ? check.forEach(c => onCellClick({row: {id: c}}, false)) : check ? colIds.forEach(c => onCellClick({row: {id: c}}, false)) : colIds.forEach(c => onCellClick({row: {id: c}}, false)) }/>
        },
        renderHeaderCell: (r) => {
          const colIds = rData.map(row => row.id);
          const check = (selectedRows.intersection(new Set(colIds)));
          const indeterminate = check.size && check.size < colIds.length;
          const checked = check.size && check.size === colIds.length;
          const click = () => indeterminate ? check.forEach(c => onCellClick({row: {id: c}}, false)) : check ? colIds.forEach(c => onCellClick({row: {id: c}}, false)) : colIds.forEach(c => onCellClick({row: {id: c}}, false));
          return <Checkbox sx={{p:0}} indeterminate={Boolean(indeterminate)} checked={Boolean(checked)} onClick={click}/>
        },
        renderSummaryCell: () => selectedRows.size
      },
      {
        key: "user",
        name: "Acc",
        resizable: true,
        sortable: true,
        minWidth: 50,
        renderGroupCell: GroupRow,
        type: "string",
      },
      {
        key: "groupKey",
        name: "InstrumentExp",
        resizable: true,
        sortable: true,
        minWidth: 155,
        type: "string",
        renderCell: ({row})=> <Button size="small" onClick={()=>{show_chain(row.insName, row.expStr)}}>{row.groupKey}</Button>,
        renderGroupCell: GroupRow,
        // renderSummaryCell: () => 'Total PNL'
      },
      {
        key: "strike",
        name: "Strike",
        resizable: true,
        sortable: true,
        minWidth: 100,
        renderGroupCell: GroupRow,
        cellClass: "insExp",
        type: "string",
      },
      {
        key: "lots",
        name: "Qty",
        resizable: true,
        sortable: true,
        renderGroupCell: ({ row }) => <span className={rowClass(sum(row.childRows, "tPNL"))}>{sum(row.childRows, "lots")}</span>,
        minWidth: 40,
        type: "number"
      },
      {
        key: "ltp",
        name: "LTP",
        resizable: true,
        sortable: true,
        minWidth: 110,
        renderCell: ({row}) => parseFloat(row?.ltp ?? 0).toFixed(2),
        renderGroupCell: (row)=> balances[row.groupKey]?<span>Mar: <span style={{color: 'yellow'}}>{balances[row.groupKey].margins ?? 0}</span> </span>: <GroupRowCell id={'fprc'} row={row} />,
        type: "number"
      },
      {
        key: "tPNL",
        name: "TotalPNL",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.tPNL ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => {
          const click = group.indexOf('groupKey') === row.level;
          const clickEvnt = (e) => { click && getWhatIfData(row.childRows[0].insName, row.childRows[0].expStr, group.includes('user') ? row.childRows[0].user : "", e.currentTarget);}
          return <div title={click ? 'Double click to open whatIF !' : ''} className={rowClass(sum(row.childRows, "tPNL"))} style={{display: 'flex', justifyContent: 'center', cursor: click && 'pointer'}} onDoubleClick={clickEvnt}><Typography>{parseFloat(sum(row.childRows, "tPNL")).toFixed(2)}</Typography>{ click && <AirlineStopsIcon sx={{color: 'white'}} onClick={clickEvnt} />}</div>
        },
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.tpnl ?? 0).toFixed(2)}</span>,
        minWidth: 100,
        type: "number"
      },
      {
        key: "delta",
        name: "Exposure",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.delta ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => <span className={rowClass(sum(row.childRows, "tPNL"))}>{parseFloat(sum(row.childRows, "delta")).toFixed(2)}</span>,
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.delta ?? 0).toFixed(2)}</span>,
        minWidth: 80,
        type: "number"
      },
      {
        key: "vega",
        name: "Vega",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseInt(row?.vega ?? 0),
        renderGroupCell: ({ row }) => <span className={rowClass(sum(row.childRows, "tPNL"))}>{sum(row.childRows, "vega")}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.vega ?? 0)}</span>,
        minWidth: 50,
        type: "number"
      },
      {
        key: "prem",
        name: "Premium",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseInt(row?.prem ?? 0),
        renderGroupCell: ({ row }) => <span className={rowClass(sum(row.childRows, "tPNL"))}>{sum(row.childRows, "prem")}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.prem ?? 0)}</span>,
        minWidth: 75,
        type: "number"
      },
      {
        key: "theta",
        name: "Theta",
        resizable: true,
        sortable: true,
        minWidth: 50,
        renderCell: ({row}) => parseInt(row?.theta ?? 0),
        renderGroupCell: ({ row }) => <span className={rowClass(sum(row.childRows, "tPNL"))}>{parseInt(sum(row.childRows, "theta"))}</span>,
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.theta ?? 0)}</span>,
        type: "number"
      },
      {
        key: "iv",
        name: "IV",
        resizable: true,
        sortable: true,
        minWidth: 50,
        renderGroupCell: (row)=> (<GroupRowCell id={'iv'} row={row} />),
        type: "number"
      },
      {
        key: "cost",
        name: "Cost",
        resizable: true,
        sortable: true,
        renderCell: ({row}) => parseFloat(row?.cost ?? 0).toFixed(2),
        renderGroupCell: ({ row }) => <span className={rowClass(sum(row.childRows, "tPNL"))}>{parseFloat(sum(row.childRows, "cost")).toFixed(2)}</span>, 
        renderSummaryCell: () => <span className={rowClass(totalSumPNLDict?.tpnl ?? 0)}>{parseFloat(totalSumPNLDict?.cost ?? 0).toFixed(2)}</span>,
        minWidth: 65,
        type: "number"
      }
    ]
  }, [show_chain, balances, GroupRow, totalSumPNLDict, getWhatIfData, group, selectedRows, rData, onCellClick])

  React.useEffect(()=>{
    if(socket){
      socket.on("pnlUpdate", (d)=>{
        setSData(d);
        if(d?.insExp){
          const t = {};
          Object.keys(d.insExp).forEach((i)=>{
            const expInst = d.insExp[i];
            t[i]= {"lts": expInst["lts"] ?? 1, "iv": expInst["iv"] ?? 0, "fprc": expInst["fprc"] ?? 0 }
          })
          setInsExpData(t)
        }
      });
    }

    return () =>{
      if(socket){
        socket.off("pnlUpdate")
      }
    }

  }, [socket])

  React.useEffect(()=>{
    if(socket && postions.length > 0){
      
      socket.emit("update_preferences", {
        pref_type: "pnl",
        pnl_data: postions.map((o)=> ({instrument: o.insName, symbol: o.ins, expiry: o.expStr}))
      })
    }

  }, [postions, socket])
  
  React.useEffect(()=>{
    const interval = setInterval(() => {

      if (document.visibilityState === 'visible') {
        const now = moment().tz("Asia/Kolkata");
        const dayOfWeek = now.isoWeekday(); // 1: Monday, ..., 7: Sunday
        const start = moment().tz("Asia/Kolkata").set({ hour: 9, minute: 0, second: 0 });
        const end = moment().tz("Asia/Kolkata").set({ hour: 15, minute: 30, second: 0 });

        if (socket && dayOfWeek >= 1 && dayOfWeek <= 5 && now.isBetween(start, end)) {
          socket.emit('g_data', 'pnl')
        }
      }
    }, 1000*updateInterval); // Update count every second

    return () =>{
      clearInterval(interval);
    }

  }, [socket, updateInterval])

  React.useEffect(()=>{
    const totalSum = {};

    const rowData = postions.map((d)=>{
      const liveD = sData[d.ins]
      const avgQty = d.position ?? 0
      const fprc = liveD && liveD.fprc ? liveD.fprc : 0
      const ltp = liveD && liveD.fp ? liveD.fp : 0

      const tmp = {
        vega: liveD && liveD.vg ? parseInt(liveD.vg * avgQty) : 0,
        theta: liveD && liveD.tt ? parseInt(liveD.tt * avgQty) : 0,
        prem: parseInt(d.id.includes("FUT") ? 0 : ltp * avgQty * -1),
        tPNL: (d.sell_val - d.buy_val) + (avgQty * ltp),
        iv: liveD && liveD.iv ? liveD.iv : 0,
        delta: liveD && liveD.dlt ? (fprc * avgQty * liveD.dlt)/100000 : 0,
        ltp,
        ...d
      }

      totalSum["vega"] = (totalSum?.vega ?? 0) + tmp.vega
      totalSum["delta"] = (totalSum?.delta ?? 0) + tmp.delta
      totalSum["prem"] = (totalSum?.prem ?? 0) + tmp.prem
      totalSum["theta"] = (totalSum?.theta ?? 0) + tmp.theta
      totalSum["tpnl"] = (totalSum?.tpnl ?? 0) + tmp.tPNL
      totalSum["cost"] = (totalSum?.cost ?? 0) + tmp.cost

      return(tmp)

    })

    // setTotalPNLDict(totalExp);
    setTotalSumPNLDict(totalSum);
    setRData( sortColumn.length ? sortRows(rowData, sortColumn) : rowData);
    
  }, [sData, postions, sortColumn])

  const summaryRows = [
    { id: 'total'}
  ];

  return (
    rData.length ? 
      <div style={{width: '95%', padding: '1rem .5rem 0rem .5rem', margin: '0px auto', textAlign: 'center', position: 'relative'}}>
        <GroupSelect />
        <TreeDataGrid style={{height: "auto", fontSize: '1rem'}} columns={m_columns} groupBy={group} rowGrouper={rowGrouper} sortColumns={sortColumn} onSortColumnsChange={(r) => handleSort(r, setSortColumn)} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rowKeyGetter={(row) => row.id} selectedRows={selectedRows} onSelectedRowsChange={setSelectedRows} onCellClick={onCellClick} rows={rData} rowClass={rowClass} bottomSummaryRows={summaryRows}/>
        {
          selectedRows.size ?
          <Box width={'100%'} marginTop={"0.5rem"}>
            <Box display={'flex'} width={'100%'} justifyContent={"center"}>
              <Button variant="outlined" sx={{color: "#22c55e", mx:"0.5rem" }} onClick={()=> placeOdrMarket(1*orderLot, selectedRows, setSelectedRows, dispatch, orderAgress) } >Buy</Button>
              <TextField size='small' sx={{mx: '0.5rem'}} id="odrLot" type='number' label="Lots" variant="outlined" value={orderLot} onChange={(e)=> setOrderLot(e.target.value) } inputProps={{min:0}} />
              <TextField size='small' sx={{mx: '0.5rem'}} inputProps={{step: 0.05 }} type='number' id="odrAgress" label={"Agress"} variant="outlined" value={orderAgress} onChange={(e) => setOrderAgress(e.target.value)} />
              <Button variant="outlined" sx={{color: "#f72b2b", mx:"0.5rem" }} onClick={()=> placeOdrMarket(-1*orderLot, selectedRows, setSelectedRows, dispatch, orderAgress)} >Sell</Button>
            </Box>
          </Box>
          : null
        }
        <Popover 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorBS)}
          anchorEl={anchorBS}
          onClose={()=> { setAnchorBS(null); setSplit(def_split); }}
        >
          {
            anchorBS ? 
            <Box display={'flex'} margin={'0.5rem'}>
              <Box display={'flex'} width={'100%'} justifyContent={"flex-end"}>
              <TextField size='small' sx={{mx: '0.2rem', width: '7rem'}} type='number' id="expo" label="Exposure" variant="outlined" defaultValue={split.type === "modify" ? splitout_data?.[split.user]?.[split.portfolio].exposure : split.exposure} value={split.exposure} onChange={(e) => setSplit({...split, exposure: parseInt(e.currentTarget.value)})} inputProps={{min: 0, style: { textAlign: 'center' }}} />
              <TextField size='small' sx={{mx: '0.2rem', width: '7rem'}} id="agress" type='number' label="Agress" variant="outlined" value={split.agres} onChange={(e) => setSplit({...split, agres: parseFloat(e.currentTarget.value)})} inputProps={{step: 0.05, style: { textAlign: 'center' }}} />
              <Checkbox sx={{mx: '0.2rem'}} id="delta_order" title='Delta Order' checked={split.delta_order} onChange={() => setSplit({...split, delta_order: !split.delta_order})} />
              <Button size='small' color='secondary' variant="outlined" sx={{mx:"0.2rem" }} onClick={() => handleSplit()} >{split.type === "modify" ? "Modify" : "Start"}</Button>
              </Box>
            </Box> 
            : null
          }
        </Popover>

        <Popover 
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={()=>{setAnchorEl(null); (hovType === 'whatIf' && dispatch(clearWhatIf())); setHovType("");}}
        >
          {
            hovType === 'whatIf' ?
            <Box sx={{background: 'black',  padding:'1rem'}}>
              {
                whatIf?.negRange ?
                <>
                  <Typography sx={{margin: 0, color: '#f72b2b'}}>{whatIf?.negRange?.toString()}</Typography>
                  <Typography sx={{margin: 0, color: '#22c55e'}}>{whatIf?.posRange?.toString()}</Typography>
                  <Typography sx={{margin: 0, color: '#c0fb13'}}>{whatIf?.avg}</Typography>
                </>
                : <Typography sx={{margin: 0, color: '#fff'}}>Loading...</Typography>
              }
            </Box>
            : null
          }
        </Popover>
      </div>
    : null
  )
  
}

export default TradeUserPosition;